import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import SEO from "../components/seo";
import Layout from "../components/layout";
import BackArrow from "../images/icons/back-arrow.svg";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const title = data.markdownRemark?.frontmatter?.title;
  const html = data.markdownRemark?.html;
  console.log(title);
  return (
    <Layout>
      <SEO title={title} />
      <TitleRow>
        <Link to="/work" replace>
          <BackArrowImg src={BackArrow} alt="back-arrow" />
        </Link>
        <Title>{title}</Title>
      </TitleRow>
      <ProjectContainer dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;

const Title = styled.h1`
  text-align: center;
  color: ${({ theme: { blue } }) => blue};
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 0;
  @media only screen and (max-width: 800px) {
    text-align: left;
  }
`;

const ProjectContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  max-width: 700px;
  margin: 0 auto;
  align-items: center;
  margin-top: 5em;
  margin-bottom: 1.5em;
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1 em;
  }
`;

const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
  margin: 0;
`;
